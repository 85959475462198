var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bannerCarousel",attrs:{"data-testid":"banner-carousel"}},[(_vm.loading)?[_vm._m(0)]:[_c('transition',{attrs:{"name":"fade","duration":{ enter: 500, leave: 800 }}},[_c('Swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.data),function(item){return (_vm.getIsMobile ? item.mobile_media : item.media)?_c('SwiperSlide',{key:item.id,staticClass:"bannerCarousel__slider"},[[_c(item.link ? 'a': 'div',{tag:"element",staticClass:"w-100 d-block img-fluid",attrs:{"href":item.link,"target":"_blank"}},[_c('img',{staticClass:"bannerCarousel__slider--image",attrs:{"id":_vm.getIsMobile  & item?.mobile_media ? item?.mobile_media?.id : item?.media?.id,"srcset":(_vm.getIsMobile && item?.mobile_media
                  ? item?.mobile_media?.small_horizontal ?? item?.mobile_media?.cdn_url
                  : item?.media?.small_horizontal ?? item?.media?.cdn_url) +
                  ' 200w, ' +
                  (_vm.getIsMobile && item?.mobile_media
                    ? item?.mobile_media?.medium_horizontal ?? item?.mobile_media?.cdn_url
                    : item?.media?.medium_horizontal ?? item?.media?.cdn_url) +
                  ' 300w, ' +
                  (_vm.getIsMobile && item?.mobile_media
                    ? item?.mobile_media?.large_horizontal ?? item?.mobile_media?.cdn_url
                    : item?.media?.large_horizontal ?? item?.media?.cdn_url) +
                  ' 1000w',"sizes":"(max-width: 200px) 100vw, (max-width: 300px) 50vw, 33.3vw","src":_vm.getIsMobile && item?.mobile_media
                  ? item?.mobile_media?.cdn_url
                  : item?.media?.cdn_url,"alt":_vm.getIsMobile ? item?.mobile_media?.title : item?.media?.title}})])]],2):_vm._e()}),_c('div',{staticClass:"bannerCarousel__prev",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('svg',{attrs:{"width":"40","height":"40","viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"opacity":"0.2"}},[_c('path',{attrs:{"d":"M20.0007 3.3335C10.8173 3.3335 3.33398 10.8168 3.33398 20.0002C3.33398 29.1835 10.8173 36.6668 20.0007 36.6668C29.184 36.6668 36.6673 29.1835 36.6673 20.0002C36.6673 10.8168 29.184 3.3335 20.0007 3.3335ZM22.984 25.0002C23.4673 25.4835 23.4673 26.2835 22.984 26.7668C22.734 27.0168 22.4173 27.1335 22.1007 27.1335C21.784 27.1335 21.4673 27.0168 21.2173 26.7668L15.334 20.8835C14.8507 20.4002 14.8507 19.6002 15.334 19.1168L21.2173 13.2335C21.7007 12.7502 22.5007 12.7502 22.984 13.2335C23.4673 13.7168 23.4673 14.5168 22.984 15.0002L17.984 20.0002L22.984 25.0002Z","fill":"white"}})])])]),_c('div',{staticClass:"bannerCarousel__next",attrs:{"slot":"button-next"},slot:"button-next"},[_c('svg',{attrs:{"width":"40","height":"40","viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"opacity":"0.2"}},[_c('path',{attrs:{"d":"M20.0007 3.3335C10.8173 3.3335 3.33398 10.8168 3.33398 20.0002C3.33398 29.1835 10.8173 36.6668 20.0007 36.6668C29.184 36.6668 36.6673 29.1835 36.6673 20.0002C36.6673 10.8168 29.184 3.3335 20.0007 3.3335ZM24.6507 20.8835L18.7673 26.7668C18.5173 27.0168 18.2007 27.1335 17.884 27.1335C17.5673 27.1335 17.2507 27.0168 17.0007 26.7668C16.5173 26.2835 16.5173 25.4835 17.0007 25.0002L22.0007 20.0002L17.0007 15.0002C16.5173 14.5168 16.5173 13.7168 17.0007 13.2335C17.484 12.7502 18.284 12.7502 18.7673 13.2335L24.6507 19.1168C25.1507 19.6002 25.1507 20.4002 24.6507 20.8835Z","fill":"white"}})])])]),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton"},[_c('div',{staticClass:"skeleton-banner"})])
}]

export { render, staticRenderFns }